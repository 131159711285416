<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<page-header title="Events" pre-title="Overview">
			<template slot="tabs" v-if="!loading">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>
		</page-header>
		<div class="container p-2">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div
								class="input-group input-group-flush d-flex flex-row-reverse"
								>
								<input
									v-model.trim="search"
									class="form-control list-search"
									type="search"
									placeholder="Search"
									/>
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
							<div class="">
								<!-- <download-button
									v-if="events.length"
									:downloading="downloadingReport"
									label="Download Report"
									@download="downloadReport()"
									></download-button> -->
							</div>
						</div>
						<div class="col px-0 d-flex">
							<div class="col"></div>
							<div class="col-auto">
								<div class="col-auto" v-if="events.length">
									<v-datepicker
										style="width: 100%"
										v-model="filter.range"
										placeholder="Today"
										range
										></v-datepicker>
								</div>
							</div>
						</div>
						<b-table
							striped
							hover
							selectable
							responsive
							:items="testData"
							:fields="fields"
							:current-page="currentPage"
							:busy="fetchingEvents"
							@row-clicked="viewEvents"
							>
							<template #table-busy>
								<div
									class="d-flex flex-column justify-content-center align-items-center"
									>
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>
							<template #empty>
								<p class="text-center text-secondary">No records available</p>
							</template>
							<template #cell(title)="data">
								<p style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ShowUpcomingEvent',
											params: { eventId: data.item.id },
										}"
										>{{ data.item.title }}</router-link
										>
								</p>
							</template>
							<template #cell(user)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ManageEvent',
											params: { eventId: data.item.id },
										}"
										>
										<span>{{
											`${data.item.firstName} ${data.item.lastName}`
										}}</span>
									</router-link>
								</p>
								<p style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ShowUpcomingEvent',
											params: { eventId: data.item.id },
										}"
										>{{ data.item.email }}</router-link
										>
								</p>
							</template>
							<template #cell(createdAt)="data">
								<p style="line-height: 1">
									{{ data.item.createdAt }}
								</p>
							</template>
							<template #cell(returnTrip)="data">
								{{ data.item.returnTrip }}
							</template>
							<template #cell(attendeesEstimate)="data">
								{{ data.item.attendeesEstimate }}
							</template>
							<template #cell(location)="data">
								<div class="route__source locate">
									<img class="icon" src="@/assets/img/source_point.svg" />
									<p class="text">{{ data.item.location1 }}</p>
								</div>
								<div class="route__destination locate">
									<img class="icon" src="@/assets/img/destination_point.svg" />
									<p class="text">{{ data.item.location2 }}</p>
								</div>
							</template>
							<template #cell(status)="data">
								<div
									v-if="data.item.status === 'accepted'"
									class="status status-accepted p-1 mx-2"
									>
									accepted
								</div>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="perPage"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import DownloadButton from '@/components/core/DownloadButton.vue'
import _ from 'lodash'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'

export default {
  components: {
    DownloadButton,
    PageHeader,
    PageTabs
  },
  data () {
    return {
      fields: [
        {
          key: 'title',
          label: 'Event Title'
        },
        {
          key: 'user',
          label: 'Customer Name'
        },
        {
          key: 'createdAt',
          label: 'Date'
        },
        {
          key: 'location',
          label: 'Event Location'
        },
        {
          key: 'returnTrip',
          label: 'Trip Type'
        },
        {
          key: 'attendeesEstimate',
          label: 'No of Attendees'
        },
        {
          key: 'status',
          label: 'Status'
        }
      ],
      testData: [
        {
          id: 1,
          title: 'Redemption Camp',
          firstName: 'Ezeeee',
          email: 'Ezeeee@gmail.com',
          lastName: 'Veessss',
          createdAt: 'June 10th 2022',
          location1: 'Redemption Camp, 10113, Pakuro, lagos State.',
          location2: 'Redeemers foundation house Jos',
          trip_type: 'Return',
          attendeesEstimate: 3000,
          status: 'accepted'
        },
        {
          id: 1,
          title: 'Redemption Camp',
          firstName: 'Ezeeee',
          email: 'Ezeeee@gmail.com',
          lastName: 'Veessss',
          createdAt: 'June 10th 2022',
          location1: 'Redemption Camp, 10113, Pakuro, lagos State.',
          location2: 'Redeemers foundation house Jos',
          trip_type: 'Return',
          attendeesEstimate: 3000,
          status: 'accepted'
        },
        {
          id: 2,
          title: 'Redemption Camp',
          firstName: 'Ezeeee',
          email: 'Ezeeee@gmail.com',
          lastName: 'Veees',
          createdAt: 'June 10th 2022',
          location1: 'Redemption Camp, 10113, Pakuro, lagos State.',
          location2: 'Redeemers foundation house Jos',
          trip_type: 'Return',
          attendeesEstimate: 3000,
          status: 'accepted'
        },
        {
          id: 3,
          title: 'Redemption Camp',
          firstName: 'Ezeeee',
          email: 'Ezeeee@gmail.com',
          lastName: 'Veees',
          createdAt: 'June 10th 2022',
          location1: 'Redemption Camp, 10113, Pakuro, lagos State.',
          location2: 'Redeemers foundation house Jos',
          trip_type: 'Return',
          attendeesEstimate: 3000,
          status: 'accepted'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      events: [],
      loading: false,
      fetchingEvents: true,
      search: '',
      processing: false,
      statusFilter: 'active',
      downloadingReport: false,
      filter: {
        range: []
      },
      statusBar: ['Active', 'Inactive', 'Suspend']
    }
  },
  created () {
    this.initEvents()
    this.debouncedCallback = _.debounce(() => {
      this.initEvents(true)
    }, 1000)
  },
  computed: {
    filteredEvents () {
      const search = this.search.toLowerCase()

      const events = this.events.filter((event) => {
        return (
          event.fname.toLowerCase().includes(search) ||
          event.lname.toLowerCase().includes(search) ||
          event.email.toLowerCase().includes(search) ||
          events.phone_number.toLowerCase().includes(search)
        )
      })

      return events
    },

    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage () {
      this.initEvents()
    },
    search () {
      this.debouncedCallback()
    },
    statusFilter () {
      this.initEvents(true)
    },
    dateFilterValid () {
      this.initEvents(true)
    }
  },
  methods: {
    selectSortOrder (value) {
      this.statusFilter = value
    },
    async initEvents () {
      this.fetchingEvents = true
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )

      const url = `/v1/users${this.search ? '&search=' : ''}?limit=${
        this.perPage
      }&page=${
        this.currentPage
      }&metadata=true&sort[id]=desc&related=itineraries&status=${
        this.statusFilter
      }`
      const filter_url = `/v1/users?metadata=true&page=${this.currentPage}&limit=${this.perPage}&related=itineraries&start_date_filter=${dateRange[0]}&end_date_filter=${dateRange[1]}`
      const request = this.search
        ? this.axios.post(url, {
          event: this.search || '',
          creation_date_start: this.dateFilterValid ? dateRange[0] : null,
          creation_date_end: this.dateFilterValid ? dateRange[1] : null
        })
        : this.dateFilterValid
          ? this.axios.get(filter_url)
          : this.axios.get(url)
      request
        .then((res) => {
          this.events = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.events
        })
        .finally(() => (this.fetchingEvents = false))
    },

    reset () {
      this.processing = false
      this.selectedEvents = []
      this.checkAllEvents = false
    },
    // viewDriver(driver) {
    //   this.$router.push({ name: 'ShowDriver', params: { driverId: driver.id } })
    // },
    downloadReport () {
      this.downloadingReport = true
      const csvData = this.events.map((data) => {
        const evenRoutes = data.routes_assigned.map((eachEven) => {
          return eachEven
        })
        return {
          name: data.fname + ' ' + data.lname,
          average_rating: data.average_rating,
          phone_number: data.phone_number,
          routes_assigned: evenRoutes,
          status:
            data.active == 1
              ? 'Active'
              : data.active == 0
                ? 'Inactive'
                : 'suspended'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length
          ? 'Drivers List'
          : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length
          ? 'Drivers List'
          : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Average Rating',
          'Phone',
          'Routes assigned',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.downloadingReport = false

      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    viewEvents (even) {
      this.$router.push({ name: 'ShowEventList', params: { eventId: even.id } })
    },
    beforeDestroy () {
      this.debouncedCallback.cancel()
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.status {
  display: flex;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}
.status-accepted {
  background: #0dac5c;
}
.status-pending {
  background: #cbaf02;
}
.status-queried {
  background: #4848ed;
}
.status-rejected {
  background: #c6c8c7;
}
.locate {
  width: 184px;
  height: 42px;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.route {
  padding: 0 0.25rem;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  max-width: 100%;

  &__source {
    position: relative;
    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 1.25rem;
      left: 0.28125rem;
      height: 70%;
      content: '';
      z-index: 0;
    }
  }
  &__source,
  &__destination {
    display: flex;
    flex-wrap: nowrap;
    color: #313533;
    .icon {
      margin-right: 0.5rem;
      width: 0.8rem;
      z-index: 2;
    }
    .text {
      /*      display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;*/
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 1.5rem 0;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      //  Container has to have a max-width attribute
    }
  }
}
</style>
